
<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="cinfo"
                sort-by="id"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2 ml-2"
                      >Company Information</v-toolbar-title
                    >

                    <v-spacer></v-spacer>

                    <v-dialog v-model="dialog" max-width="900px">
                      <!-- <template v-slot:activator="{ on, attrs }" v-if>
                        <v-btn
                          color="primary"
                          dark
                          to="/dashboard/pages/addcompanyinfo"
                          class="mt-1"
                        >
                          <v-icon color="white">mdi-plus</v-icon>Add Company
                          Info
                        </v-btn>
                      </template> -->
                      <!-- <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2 mt-2 mr-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-plus</v-icon>
                          Add Product
                        </v-btn>
                      </template> -->

                      <v-card style="background: #f5f5f5" elevation="0">
                        <v-row justify="end" class="pr-10">
                          <v-btn
                            icon
                            color="red"
                            @click.stop="dialog = false"
                            large
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </v-row>
                        <v-card-text>
                          <v-container class="pt-0">
                            <v-card elevation="0">
                              <v-card-text
                                style="background: #f5f5f5"
                                class="pt-0"
                              >
                                <v-form>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        class="mt-3"
                                        v-model="editedItem.name"
                                        label="Name of your company"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        class="mt-3"
                                        v-model="editedItem.address"
                                        label="Address of your company"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        v-model="editedItem.email"
                                        label="Company Email"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        v-model="editedItem.phone"
                                        label="Phone Number"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        v-model="editedItem.help_center"
                                        label="Hot Line"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        v-model="editedItem.About"
                                        label="About"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        v-model="editedItem.slogan"
                                        label="Company Slogan"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        v-model="editedItem.Facebook"
                                        label="Facebook"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        v-model="editedItem.twitter"
                                        label="twitter "
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        v-model="editedItem.linkedin"
                                        label="linkedin"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <!-- <v-col cols="12">
                                      <v-text-field
                                        v-model="editedItem.youtube"
                                        label="youtube "
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col> -->
                                  </v-row>

                                  <!-- <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                      <template>
                                        <v-file-input
                                          v-model="editedItem.logo"
                                          label="Company Logo"
                                          filled
                                          prepend-icon="mdi-camera"
                                        ></v-file-input>
                                      </template>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                      <template>
                                        <v-file-input
                                          v-model="editedItem.icon"
                                          label="Icon"
                                          filled
                                          prepend-icon="mdi-camera"
                                        ></v-file-input>
                                      </template>
                                    </v-col>
                                  </v-row> -->

                                  <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        v-for="(term,
                                        index) in editedItem.terms_condition"
                                        :key="index"
                                        v-model="
                                          editedItem.terms_condition[index]
                                        "
                                        label="Company Tearms and conditions"
                                        outlined
                                        dense
                                      ></v-text-field>
                                      <v-btn @click="addTerms" color="success"
                                        >Add Terms and Conditions</v-btn
                                      >
                                    </v-col>

                                    <v-col cols="12" sm="6" md="6">
                                      <v-text-field
                                        v-for="(policy,
                                        index) in editedItem.policy"
                                        :key="index"
                                        v-model="editedItem.policy[index]"
                                        label="Company policies"
                                        outlined
                                        dense
                                      ></v-text-field>
                                      <v-btn @click="addPolicy" color="success"
                                        >Add Policy</v-btn
                                      >
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-card-text>
                            </v-card>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red" text @click="close">Cancel</v-btn>
                          <v-btn
                            color="blue darken-1"
                            @click="EditCompanyInfo()"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    text: "",
    dialog: false,
    key_features: [],
    headers: [
      {
        text: "Company Name",
        align: "start",
        sortable: false,
        value: "name",
      },

      { text: "Address", value: "address", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Phone", value: "phone", sortable: false },
      { text: "Hot Line", value: "help_center", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    cinfo: [],
    contact: {},

    editedIndex: -1,
    editedItem: {
      name: "",
      address: "",
      icon: "",
      email: "",
      phone: "",
      help_center: "",
      policy: [],
      About: "",
      terms_condition: [],
      slogan: "",
      Facebook: "",
      twitter: "",
      linkedin: "",
      youtube: "",
      logo: "",
    },
    defaultItem: {
      name: "",
      address: "",
      icon: "",
      email: "",
      phone: "",
      help_center: "",
      policy: [],
      About: "",
      terms_condition: [],
      slogan: "",
      Facebook: "",
      twitter: "",
      linkedin: "",
      youtube: "",
      logo: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Info" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    initialize() {
      axios.get("site/info/").then((response) => {

        this.cinfo = response.data.data;
      });
    },

    editItem(item) {

      this.editedItem = item;
      this.dialog = true;
    },

    deleteItem(item) {
      if (confirm("Do you really want to delete?")) {
        axios.post(`site/delete_info/${item.id}/`).then((response) => {
          this.cinfo = this.cinfo.filter((contact) => contact.id !== item.id);
        });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    addTerms() {
      this.editedItem.terms_condition.push("");
    },

    addPolicy() {
      this.editedItem.policy.push("");
    },

    EditCompanyInfo() {


      axios
        .post("site/addinfo/", this.editedItem)
        .then((response) => {
          this.text = "Company Information updated successfully !";
          this.close();
          this.snackbar = true;
        })
        .catch((err) => {
          this.text = "Somethong went wrong !!";
          this.close();
          this.snackbar = true;
        });
    },

    saveEdit() {
      // let formData = new FormData();
      // function buildFormData(formData, data, parentKey) {
      //   if (
      //     data &&
      //     typeof data === "object" &&
      //     !(data instanceof Date) &&
      //     !(data instanceof File)
      //   ) {
      //     Object.keys(data).forEach((key) => {
      //       buildFormData(
      //         formData,
      //         data[key],
      //         parentKey ? `${parentKey}[${key}]` : key
      //       );
      //     });
      //   } else {
      //     const value = data == null ? "" : data;
      //     formData.append(parentKey, value);
      //   }
      // }
      // buildFormData(formData, this.editedItem);
      // for (var pair of formData.entries()) {
     
      // }
      // axios
      //   .post(`site/update_info/`, formData, {
      //     headers: { "Content-Type": "multipart/form-data" },
      //   })
      //   .then((response) => {
      // 
      //     this.dialog = false;
      //   });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>


